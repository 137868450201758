/* src/app/app.component.less */
.menu-icon {
  cursor: pointer;
  padding: 15px 15px 15px 0;
  color: #e7da00;
}
.text-category {
  font-family: Gotham;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
}
::ng-deep .mdc-list-item__primary-text {
  display: flex !important;
  align-items: center !important;
  column-gap: 20px;
}
mat-toolbar {
  height: 125px !important;
  justify-content: center !important;
}
::ng-deep .mat-drawer-container {
  background-color: #e2e2e2;
}
.refresher {
  z-index: 5;
  margin-top: 70px;
  background-color: white;
}
.img-container {
  display: flex;
  align-items: center;
}
.img-container .logo {
  width: 105px;
  height: 105px;
}
@media (max-width: 650px) {
  .refresher {
    margin-top: 95px;
  }
}
@media (max-width: 360px) {
  .refresher {
    margin-top: 105px;
  }
}
::ng-deep .mat-toolbar {
  background: white;
}
.nav-bar {
  position: fixed;
  z-index: 5;
}
.safearea {
  margin-top: env(safe-area-inset-top);
}
.smooth {
  scroll-behavior: smooth;
}
.auto-icon {
  bottom: 0;
  position: relative;
}
.mat-drawer:not(.mat-drawer-side) {
  position: fixed;
}
.checkBox {
  margin-left: 30px !important;
}
.mat-toolbar-multiple-rows {
  top: 0px !important;
}
.logo-desktop {
  display: block;
}
.logo-mobile {
  display: none;
}
@media screen and (max-width: 940px) {
  mat-toolbar {
    height: 150px !important;
  }
}
@media screen and (max-width: 800px) {
  .logo-desktop {
    display: none;
  }
  mat-list-item:first-of-type {
    margin-top: 10px;
  }
  mat-toolbar {
    height: 130px !important;
  }
  .logo-mobile {
    display: block;
  }
}
::ng-deep .mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent !important;
}
.select-icon {
  color: #304269 !important;
}
::ng-deep .mat-badge-content {
  background: #009db2 !important;
  color: #fff !important;
}
app-switch {
  margin-left: 15px !important;
}
/*# sourceMappingURL=app.component-VY2RXOZS.css.map */
