/* src/app/pages/cart/mini-cart/mini-cart.component.less */
.cart-home {
  position: fixed;
  right: 20px;
  top: 130px;
  z-index: 4;
  width: 20%;
  height: auto;
  max-height: 65%;
  overflow: scroll;
  scrollbar-width: none;
  padding: 20px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.cart-home .info-cart-home {
  display: flex;
  justify-content: space-between;
}
.cart-home .info-cart-home p,
.cart-home .info-cart-home a {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #545454;
}
.cart-home .info-cart-home p b,
.cart-home .info-cart-home a b {
  color: black;
}
.cart-home .info-cart-home a {
  color: #009db2 !important;
  text-decoration: none;
  cursor: pointer;
}
mat-card {
  box-shadow: none !important;
  border: 1px solid #e2e2e2 !important;
  height: 160px;
  margin-bottom: 15px !important;
}
mat-card img {
  width: 72px;
  height: 72px;
}
mat-card .name-product {
  font-family: "Poppins-SemiBold" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
mat-card .info-text {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
}
.total-button {
  flex-direction: column;
}
.hr {
  border: 1px solid #d0d0d0;
  margin: 5px;
  width: 100%;
}
.subtotal {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e5e5e5;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.subtotal p {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #878787;
}
.subtotal span {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.input-products {
  display: flex;
  align-items: center;
  border: 2px solid #e2e2e2;
  border-radius: 20px;
  height: 30px;
}
.input-products input {
  font-family: "Poppins-SemiBold" !important;
  border: none;
  width: 20px;
  color: #304269;
  font-weight: 400;
}
.product-price {
  font-family: "Poppins-SemiBold" !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
}
.info-price-home {
  position: relative;
  bottom: -20px;
  justify-content: space-around;
}
@media screen and (max-width: 940px) {
  .cart-home {
    top: 140px;
    width: 30%;
  }
}
@media screen and (max-width: 590px) {
  .cart-home {
    width: 40%;
  }
}
@media screen and (max-width: 500px) {
  .cart-home {
    width: 82%;
    right: 10px;
    min-height: 70%;
  }
}
/*# sourceMappingURL=mini-cart.component-XYIQTQLM.css.map */
